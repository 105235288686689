<template>
  <div class="container" @click="goToHome">
    <div class="content pop"
      :style="{ animationDelay: `0s`, animationDuration: `0.2s` }"
      >
      <img key="logo" class="rotate" src="@/assets/logo-blue-rotation-centered.png" />
      <p>Participez à notre<br/>⟼ jeu-concours !</p>
    </div>
    <div class="footer">
      Made by Des Choses Pareilles - Version {{ $store.state.packageVersion }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  mounted() {

  },
  beforeUnmount() {

  },
  methods: {
    goToHome() {
      window.location.href = "https://genedis.ch/concours";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: $secondary-color;
}

p {
  font-size: 4em;
}

img {
  max-width: 40vw;
  max-height: 40vh;
}

.rotate {
  animation: rotateAnticlockwise 5s infinite;
}

/* Keyframes for rotation */
@keyframes rotateAnticlockwise {
  30% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-360deg); /* Complete rotation anticlockwise */
  }
  100% {
    transform: rotate(-360deg); /* Complete rotation anticlockwise */
  }
}

.pop {
  transform: scale(0);
  animation-name: pop-scale;
  animation-fill-mode: forwards;
  transition: background-color 0.3s ease;
}

/* Animation de pop */
@keyframes pop-scale {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 0.7;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
