import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ScreenSaverView from '../views/ScreenSaverView.vue'
import IFrameView from '../views/IFrameView.vue'

const routes = [
  {
    path: '/',
    name: 'screensaver',
    component: ScreenSaverView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    props: {
      buttons: [
        { text: 'Je suis un particulier', route: {name: 'private-home'} },
        { text: 'Je suis une entreprise', route : { name: 'company-home'} },
        { text: 'Calculateur solaire',route : { name: 'site',  query : { url : 'https://rechner.eturnity.ch/setelec/'}} },
        { text: 'EnergyFit', route : { name: 'site',  query : { url : 'https://www.energyfit-genedis.ch/'}} },
        { text: 'Energycheck', route : { name: 'site',  query : { url : 'https://www.genedis.ch/particuliers/energie/audit-energetique/energycheck'}} },
        { text: 'Concours', route : { name: 'site',  query : { url : 'https://www.genedis.ch/concours-fdv-2024'}} }
      ]
    }
  },
  {
    path: '/private-home',
    name: 'private-home',
    component: HomeView,
    props: {
      buttons: [
        { text: 'Solaire clefs en mains', route : { name: 'site',  query : { url : 'https://www.genedis.ch/particuliers/energie/solaire/installation-photovoltaique-cles-en-main'}} },
        { text: 'Stockage de l\'énergie', route : { name: 'site',  query : { url : 'https://www.genedis.ch/calculateur-horizon-2025'}} },
        { text: 'Chauffage clefs en mains', route : { name: 'site',  query : { url : 'https://www.genedis.ch/particuliers/energie/chaleur/installation-thermique-cles-en-main'}} },
        { text: 'Bornes de recharge', route : { name: 'site',  query : { url : 'https://www.genedis.ch/particuliers/energie/mobilite-electrique/bornes-de-recharge'}} },
        { text: 'Energycheck', route : { name: 'site',  query : { url : 'https://www.genedis.ch/particuliers/energie/audit-energetique/energycheck'}} },
        { text: 'Auditelec', route : { name: 'site',  query : { url : 'https://www.genedis.ch/particuliers/energie/audit-energetique/auditelec'}} }
      ]
    }
  },
  {
    path: '/company-home',
    name: 'company-home',
    component: HomeView,
    props: {
      buttons: [
        { text: 'Solaire clefs en mains', route : { name: 'site',  query : { url : 'https://www.genedis.ch/professionnels/entreprises/energie/solaire/installation-photovoltaique-cles-en-main'}} },
        { text: 'Contracting solaire', route : { name: 'site',   query : { url : 'https://www.genedis.ch/professionnels/entreprises/energie/solaire/contracting-photovoltaique'}}},
        { text: 'Stockage de l\'énergie',  route : { name: 'site',  query : { url : 'https://www.genedis.ch/professionnels/entreprises/energie/solaire/stockage-de-lenergie'}}},
        { text: 'Chauffage clefs en mains solaire', route : { name: 'site', query : { url : 'https://www.genedis.ch/professionnels/entreprises/energie/chaleur/installation-thermique-cle-en-main' }}},
        { text: 'Contracting-thermique', route : { name: 'site', query: { url : 'https://www.genedis.ch/professionnels/entreprises/energie/chaleur/contracting-thermique' }}},
        { text: 'Bornes de recharge', route : { name: 'site', query: { url : 'https://www.genedis.ch/professionnels/entreprises/energie/mobilite-electrique/bornes-de-recharge' }}},
        { text: 'Audit énergétiques', route : { name: 'site', query: { url : 'https://www.genedis.ch/professionnels/entreprises/energie/audit-energetique' }}},
      ]
    }
  },
  {
    path: '/site',
    name: 'site',
    component: IFrameView,
    props: route => ({ iframeUrl: route.query.url })
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;